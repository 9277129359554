/**
 * Accessibility tools JavaScript
 */

(function(w, d) {

	'use strict';

	if (!d.addEventListener ||
		!d.querySelector ||
		!d.body.classList.add) {
		return;
	}

	var contrastSwitch = d.querySelector('.contrast-switch');
	var contrastSwitchElement = null;
	var contrastSwitchConfig = null;
	var fontSizeSwitch = d.querySelector('.font-switch');
	var fontSizeSwitchElements = null;
	var fontSizeSwitchConfig = null;
	var configAttributeValue = null;

	if (contrastSwitch) {
		configAttributeValue = contrastSwitch.getAttribute('data-tay-contrast-switch-config');

		if (configAttributeValue) {
			contrastSwitchConfig = JSON.parse(configAttributeValue);
		}
	}

	if (fontSizeSwitch) {
		configAttributeValue = fontSizeSwitch.getAttribute('data-tay-font-switch-config');

		if (configAttributeValue) {
			fontSizeSwitchConfig = JSON.parse(configAttributeValue);
		}
	}

	function setCookie(cookieName, value) {
		var date = new Date();
		date.setTime(date.getTime() + (12 * 30 * 24 * 60 * 60 * 1000));
		var cookieString = cookieName + '=' + encodeURIComponent(value) + '; expires=' + date.toUTCString() + '; path=/';
		d.cookie = cookieString;
	}

	function analyticsEventTracking(category, action) {
		if (typeof w.gtag !== 'function') {
			return;
		}

		gtag('event', action, {
			'event_category': category
		});
	}

	function toggleContrastSwitch(event) {
		if (event.type === 'keydown' && event.key && event.key !== ' ' && event.key !== 'Spacebar') {
			return;
		}

		var element = event.currentTarget;
		var textElement = element.querySelector('.contrast-switch__link-text');
		var value = parseInt(element.getAttribute('data-tay-contrast-value'), 10);
		var checked = element.getAttribute('aria-checked') === 'true';

		event.preventDefault();
		element.setAttribute('aria-checked', !checked);
		element.setAttribute('data-tay-contrast-value', value === 0 ? 1 : 0);
		element.classList.toggle(contrastSwitchConfig.switch_active_class);
		textElement.innerText = checked ? contrastSwitchConfig.text_off : contrastSwitchConfig.text_on;
		d.body.classList.toggle(contrastSwitchConfig.active_class);
		analyticsEventTracking('Saavutettavuus, korkea kontrasti', checked ? 'Pois' : 'Päälle');
		setCookie(contrastSwitchConfig.cookie_name, value);
	}

	function toggleFontSwitch(event) {
		var element = event.currentTarget;
		event.preventDefault();

		if (element.hasAttribute('aria-current')) {
			return;
		}

		var currentElement = fontSizeSwitch.querySelector('[aria-current]');
		var currentValue = parseInt(currentElement.getAttribute('data-tay-font-size-value'), 10);
		var value = parseInt(element.getAttribute('data-tay-font-size-value'), 10);

		currentElement.removeAttribute('aria-current');
		element.setAttribute('aria-current', 'true');

		if (fontSizeSwitchConfig.sizes[currentValue].class) {
			d.documentElement.classList.remove(fontSizeSwitchConfig.sizes[currentValue].class);
		}

		if (fontSizeSwitchConfig.sizes[value].class) {
			d.documentElement.classList.add(fontSizeSwitchConfig.sizes[value].class);
		}

		currentElement.querySelector('.font-switch__link-text').innerText = fontSizeSwitchConfig.sizes[currentValue].text_link;
		element.querySelector('.font-switch__link-text').innerText = fontSizeSwitchConfig.sizes[value].text_current;
		analyticsEventTracking('Saavutettavuus, tekstikoko', fontSizeSwitchConfig.sizes[value].text_current);
		setCookie(fontSizeSwitchConfig.cookie_name, value);
	}

	if (contrastSwitchConfig && contrastSwitch) {
		contrastSwitchElement = contrastSwitch.querySelector('.contrast-switch__link');
		contrastSwitchElement.setAttribute('role', 'switch');
		contrastSwitchElement.setAttribute('aria-checked', !parseInt(contrastSwitchElement.getAttribute('data-tay-contrast-value'), 10));
		contrastSwitchElement.addEventListener('click', toggleContrastSwitch, false);
		contrastSwitchElement.addEventListener('keydown', toggleContrastSwitch, false);
	}

	if (fontSizeSwitchConfig && fontSizeSwitch) {
		fontSizeSwitchElements = fontSizeSwitch.querySelectorAll('.font-switch__link');

		for (var x = 0; x < fontSizeSwitchElements.length; x++) {
			fontSizeSwitchElements[x].addEventListener('click', toggleFontSwitch, false);
		}
	}
})(this, document);
